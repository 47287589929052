import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/header";
import Footer from "./components/footer";
import { ScrollProvider } from "./utils/ScrollContext";
import { ToastContainer } from "react-toastify";
import LanguageProvider from './utils/LanguageContext';
import { HelmetProvider } from 'react-helmet-async';

// Lazy loading pages
const HomePage = lazy(() => import("./pages/Home"));
const PortfolioPage = lazy(() => import("./pages/Portfolio"));

const App: React.FC = () => {
    return (
        <HelmetProvider>
            <Router>
                <ScrollProvider>
                    <LanguageProvider>
                        <div className="flex flex-col min-h-screen">
                            <Header />
                            <main className="flex-grow">
                                <ToastContainer />
                                {/* Suspense is used to show a fallback UI while the component is being loaded */}
                                <Suspense fallback={ 
                                            <div className="flex items-center justify-center min-h-screen bg-nisipeanu-black-dark bg-opacity-80 fixed top-0 left-0 right-0 bottom-0 z-50">
                                            <div className="w-16 h-16 border-4 border-t-4 border-nisipeanu-grey-light rounded-full animate-spin" style={{ borderTopColor: '#068cc2' }}></div>
                                            </div>
                                        }>
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/portfolio" element={<PortfolioPage />} />
                                    </Routes>
                                </Suspense>
                            </main>
                            <Footer />
                        </div>
                    </LanguageProvider>
                </ScrollProvider>
            </Router>
        </HelmetProvider>
    );
}

export default App;
